import md5 from "md5";
import { defineStore } from "pinia";
import helper from "../libs/helper";
import ApiRequest from "./../libs/ApiRequest";

export const useUserStore = defineStore("user", {
  state: () => {
    let access_token = localStorage.getItem("access_token") || "";
    let user = localStorage.getItem("user") || false;
    user = user ? JSON.parse(user) : {};

    return {
      accessToken: access_token,
      isLoggedIn: Boolean(access_token),
      user: user,
      selectedProject: "",
      enteredReportSearchQuery: "",
      projects: null,
      isloading: false,
      loaderLable: "",
      isFullPage: false,
      supportedLocation: {
        success: !1,
        countries: {},
      },
      supportedInfo: {
        success: !1,
        countries: [],
        languages: [],
        domain_details: [],
        locations: [],
      },
      stopwords: [],
      serpWords: [],
      phases: {
        10: "Pending",
        20: "Initiated Scraping",
        30: "Scraping completed",
        40: "Initiated SERP analysis",
        50: "SERP analysis completed",
        60: "Initiated NLP analysis",
        70: "NLP analysis completed",
        80: "Initiated Winning Entities",

        81: "DOM vector initiated",
        82: "DOM vector completed",
        83: "DOM vector analysis started",
        84: "DOM vector analysis completed",

        85: "Text started",
        86: "Text completed",
        87: "Text analysis started",
        88: "Text  analysis completed",

        89: "WORDS started",
        90: "WORDS completed",
        91: "WORDS analysis started",
        92: "WORDS analysis completed",

        93: "Images started",
        94: "Images completed",
        95: "Images analysis started",
        96: "Images analysis completed",

        100: "Winning Entities completed",
      },
      gltrData: "",
      aiDetectionResult: "",
      KDReportsCurrentPage: null,
      connectedApps: [],
      trialWizardActiveStep: "step1",
      autoGeneratingArticles: [],
      isShowAutoGeneratedArticleInfoModal: false,
      quotas: {},
      currentDashboardStep: 0
    };
  },
  actions: {
    async setConnectedApps() {
      let extractedError = null;
      try {
        const res = await ApiRequest().get(`api/auth/wordpress/authorization`);
        if (res.status === 200) {
          this.connectedApps = res.data.results;
        }
      } catch (error) {
        extractedError = helper.extractAlert(error, "integrated-apps");
      }

      return {
        extractedError: extractedError,
        connectedApps: this.connectedApps,
      };
    },
    setKDCurrentPageUrl(pageUrl) {
      this.KDReportsCurrentPage = pageUrl;
    },
    setUser(user) {
      this.user = user;
      localStorage.setItem("user", JSON.stringify(user));
    },
    setLogin(isLoggedIn) {
      this.isLoggedIn = isLoggedIn;
    },
    signOut() {
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("access_token");
      localStorage.removeItem("user");
      this.isLoggedIn = !1;
      this.accessToken = "";
    },
    async setLoginToken(token) {
      const { access, refresh = "" } = token;
      this.accessToken = access;
      localStorage.setItem("access_token", access);
      refresh && localStorage.setItem("refresh_token", refresh);
      const URL = `/api/auth/current-user`;
      const { data } = await ApiRequest(access).get(URL);
      this.setUser(data);
    },
    loginCheck(router) {
      if (this.isLoggedIn) {
        router.push({ name: "dashboard" });
      } else {
        router.push({ name: "signin" });
      }
    },
    // which project is selected
    setSelectedProject(uuid) {
      this.selectedProject = uuid;
    },
    // Set Search Query filter
    setReportSearchQuery(query) {
      this.enteredReportSearchQuery = query;
    },
    // set projects
    setProjects(results) {
      this.projects = results;
    },
    // set loading status
    setIsLoading(state, ref = false) {
      this.isloading = Boolean(state);
      this.isFullPage = ref;
    },
    setLoaderLabel(label) {
      this.loaderLable = label;
    },
    // set locations
    async getCountries() {
      let extractedError = null;
      if (!this.supportedLocation.success) {
        try {
          const { data } = await ApiRequest().get(
            `/api/serp/search/supported-locations`
          );
          if (data.locations) {
            this.supportedLocation = {
              success: !0,
              countries: data.locations,
            };
          } else {
            throw new Error("Something went wrong");
          }
        } catch (error) {
          extractedError = helper.extractAlert(error, "locations");
        }
      }

      return {
        extractedError: extractedError,
        countries: this.supportedLocation.countries,
      };
    },
    // set locations
    async getStopwords() {
      if (this.stopwords.length < 1) {
        try {
          const URL = `/api/serp/search/stopwords`;
          const res = await ApiRequest().get(URL);
          if (res.data?.stopwords) {
            this.stopwords = res.data?.stopwords;
          }
        } catch (error) {
          this.stopwords = [];
        }
      }
      return this.stopwords;
    },
    gravatar() {
      const hash = md5((this.user.email ?? "").toLowerCase());
      return `https://www.gravatar.com/avatar/${hash}`;
    },
    hasSerpFinderPermission() {
      return this.user?.permissions?.includes("services.fresh_serp_finder");
    },
    hasSitemapAnalyzerPermission() {
      return this.user?.permissions?.includes("services.sitemap_analyzer");
    },
    hasCSVToKeywordPermission() {
      return this.user?.permissions?.includes("services.keyword_explorer");
    },
    hasKeywordPlannerPermission() {
      return true; // TODO: temporarily allow
      // return this.user?.permissions?.includes("services.keyword_explorer");
    },
    hasTokenAnalysisPermission() {
      return this.user?.permissions?.includes("services.token_analysis");
    },
    hasSEOUniSpyPermission() {
      return this.user?.permissions?.includes("services.seo_uni_spy");
    },
    hasZebraAIPermission() {
      return this.user?.permissions?.includes("services.spy_ai");
    },
    isTeamKeywordSpyTier() {
      return ["spy basic monthly", "spy team monthly"].includes(this.user.tier);
    },
    isSuperUser() {
      return Boolean(this.user?.is_superuser);
    },
    setSERPWords(words) {
      this.serpWords = words;
    },
    setGLTRData(data) {
      this.gltrData = data;
    },
    setAiDetectionResult(data) {
      this.aiDetectionResult = data;
    },
    // get supported info
    async getSupportedInfo() {
      let extractedError = null;
      if (!this.supportedInfo.success) {
        try {
          const { data } = await ApiRequest().get(
            `/api/serp/search/supported-info`
          );
          if (data.info) {
            const normalizeData = (da) => {
              return Object.keys(da).map((i) => {
                return {
                  label: da[i],
                  value: i,
                };
              });
            };
            const domains = Object.entries(data.info.domain_details).map(
              ([key, value]) => {
                value.main_country = key;
                return value;
              }
            );
            this.supportedInfo = {
              success: !0,
              countries: normalizeData(data.info.countries),
              languages: normalizeData(data.info.languages),
              domain_details: domains,
              locations: normalizeData(data.info.locations),
            };
          } else {
            throw new Error("Something went wrong");
          }
        } catch (error) {
          extractedError = helper.extractAlert(error, "supported info");
        }
      }
      return {
        extractedError: extractedError,
        countries: this.supportedInfo.countries,
        languages: this.supportedInfo.languages,
        domain_details: this.supportedInfo.domain_details,
        locations: this.supportedInfo.locations,
      };
    },
    setTrialWizardActiveStep(step) {
      this.trialWizardActiveStep = step;
    },
    setAutoGeneratingArticles(articles) {
      this.autoGeneratingArticles = articles;
    },
    setAutoGeneratedArticleInfoModalStatus(status) {
      this.isShowAutoGeneratedArticleInfoModal = status;
    },
    setQuotas(data) {
      this.quotas = data;
    },
    setCurrentDashboardStep (index) {
      this.currentDashboardStep = index
    }
  },
});
