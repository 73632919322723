<template>
  <div
    class="min-h-screen"
    :class="
      mode === 'dark'
        ? 'bg-dark-80 opacity-100 is-dark text-white-100'
        : 'bg-gray-20 bg-opacity-50 text-black-100'
    "
  >
    <div
      ref="sidebar"
      class="fixed top-0 left-0 z-[998] h-full px-2 duration-300 transition-all origin-left transform border-r without-scrollbar custom:top-[56px]"
      :class="[
        isOpen === true
          ? 'w-70 overflow-visible'
          : ' w-60 overflow-x-hidden overflow-y-auto',
        mode === 'dark' ? 'bg-dark-90 border-dark-70' : 'bg-white-100',
        isCollapsed ? 'custom:left-0' : 'custom:-left-full',
      ]"
      v-if="
        isLoggedIn &&
        $route.name !== 'signup' &&
        $route.name !== 'signup2' &&
        $route.name !== 'wordpress-authentication' &&
        !$route.meta.isHideSidebar
      "
    >
      <div
        class="sidebar-links text-sm font-medium py-2 flex flex-col justify-between h-full custom:h-sidebar"
        :class="[mode === 'dark' ? 'text-white-100' : 'text-gray-600']"
      >
        <div class="grid" :class="[isOpen ? 'gap-3' : 'gap-5']">
          <RouterLink
            :to="{ name: 'dashboard' }"
            v-slot="{ navigate, isActive, isExactActive }"
            :title="isOpen ? 'Dashboard' : ''"
            v-tooltip="{
              content: `${isOpen ? 'Dashboard' : ''}`,
              placement: 'auto',
              distance: 10,
            }"
            @click="redirect"
          >
            <div
              class="flex items-center p-2 relative rounded-md bg-opacity-5 gap-2 group"
              :class="[
                (isActive || isExactActive) && 'active',
                isOpen && 'is-open',
                mode === 'dark'
                  ? 'text-white-100 hover:bg-dark-70'
                  : 'hover:bg-primary-100 hover:text-black-100 hover:bg-opacity-5',
              ]"
              role="link"
              @click="navigate"
            >
              <span class="flex-shrink-0 w-6 h-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.344 3.692a2.25 2.25 0 0 1 3.312 0l3.854 4.19a3.75 3.75 0 0 1 .99 2.538v3.33a2.75 2.75 0 0 1-2.75 2.75h-1.75a1.5 1.5 0 0 1-1.5-1.5v-2h-1v2a1.5 1.5 0 0 1-1.5 1.5h-1.75a2.75 2.75 0 0 1-2.75-2.75v-3.33c0-.94.353-1.846.99-2.539l3.854-4.189Zm2.208 1.016a.75.75 0 0 0-1.104 0l-3.854 4.189a2.25 2.25 0 0 0-.594 1.523v3.33c0 .69.56 1.25 1.25 1.25h1.75v-2a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v2h1.75c.69 0 1.25-.56 1.25-1.25v-3.33a2.25 2.25 0 0 0-.594-1.523l-3.854-4.189Z"
                  />
                </svg>
              </span>
              <div
                class="leading-none"
                :class="isOpen === true ? 'hidden' : ''"
              >
                Dashboard
              </div>
            </div>
          </RouterLink>
          <RouterLink
            v-if="userStore.hasKeywordPlannerPermission()"
            :to="{ name: 'KeywordPlanner' }"
            v-slot="{ navigate, isActive, isExactActive }"
            :title="isOpen ? 'Keyword Discovery' : ''"
            v-tooltip="{
              content: `${isOpen ? 'Keyword Discovery' : ''}`,
              placement: 'auto',
              distance: 10,
            }"
            @click="redirect"
          >
            <div
              class="flex items-start p-2 relative rounded-md bg-opacity-5 gap-2 group"
              :class="[
                (isActive || isExactActive || isKeywordPlannerPage) && 'active',
                isOpen && 'is-open',
                mode === 'dark'
                  ? 'text-white-100 hover:bg-dark-70'
                  : 'hover:bg-primary-100 hover:text-black-100 hover:bg-opacity-5',
              ]"
              role="link"
              @click="navigate"
              v-tooltip="
                !isOpen
                  ? {
                      content: `<div style='max-width: 350px;'>START HERE Start with a base or seed keyword, and use
                  Advanced AI to discover the most lucrative and easiest
                  keywords and niches to go for! Plan Money Pages and Topical
                  clusters.</div>`,
                      html: true,
                      placement: 'auto',
                      distance: 10,
                    }
                  : null
              "
            >
              <span class="flex-shrink-0 w-6 h-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M4 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" />
                  <path d="M4 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" />
                  <path d="M5 15a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" />
                  <path
                    d="M7.25 4.25a.75.75 0 0 0 0 1.5h9a.75.75 0 0 0 0-1.5h-9Z"
                  />
                  <path
                    d="M6.5 10a.75.75 0 0 1 .75-.75h9a.75.75 0 0 1 0 1.5h-9a.75.75 0 0 1-.75-.75Z"
                  />
                  <path
                    d="M7.25 14.25a.75.75 0 0 0 0 1.5h9a.75.75 0 0 0 0-1.5h-9Z"
                  />
                </svg>
              </span>
              <div :class="isOpen === true ? 'hidden' : 'pt-[2px]'">
                <div class="leading-none">Keyword Discovery</div>
                <div class="text-[10px] pt-2 leading-none">
                  Find Best Searches to Go For​
                </div>
              </div>
            </div>
          </RouterLink>
          <RouterLink
            v-if="userStore.hasTokenAnalysisPermission()"
            :to="{ name: 'serp-keyword-spy' }"
            v-slot="{ navigate, isActive, isExactActive }"
            :title="isOpen ? 'Keyword Spy' : ''"
            v-tooltip="{
              content: `${isOpen ? 'Keyword Spy' : ''}`,
              placement: 'auto',
              distance: 10,
            }"
            @click="redirect"
          >
            <div
              class="flex items-start p-2 relative rounded-md bg-opacity-5 gap-2 group"
              :class="[
                (isActive || isExactActive) && 'active',
                isOpen && 'is-open',
                mode === 'dark'
                  ? 'text-white-100 hover:bg-dark-70'
                  : 'hover:bg-primary-100 hover:text-black-100 hover:bg-opacity-5',
              ]"
              role="link"
              @click="navigate"
              v-tooltip="
                !isOpen
                  ? {
                      content: `<div style='max-width: 350px;'>Use Keyword Spy to Spy on Your competitors and Build Your Own AI models and get your best SEO keywords</div>`,
                      html: true,
                      placement: 'auto',
                      distance: 10,
                    }
                  : null
              "
            >
              <span class="flex-shrink-0 w-6 h-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.323 13.383a5.5 5.5 0 1 1 1.06-1.06l2.897 2.897a.75.75 0 1 1-1.06 1.06l-2.897-2.897Zm.677-4.383a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
                  />
                </svg>
              </span>
              <div :class="isOpen === true ? 'hidden' : 'pt-[2px]'">
                <div class="leading-none">Keyword Spy</div>
                <div class="text-[10px] pt-2 leading-none">
                  Find Boosting Keywords​​
                </div>
              </div>
            </div>
          </RouterLink>
          <RouterLink
            :to="{ name: 'editor' }"
            v-slot="{ navigate, isActive, isExactActive }"
            :title="isOpen ? 'Editor' : ''"
            v-tooltip="{
              content: `${isOpen ? 'Article Builder' : ''}`,
              placement: 'auto',
              distance: 10,
            }"
            @click="redirect"
          >
            <div
              class="flex items-start p-2 relative rounded-md bg-opacity-5 gap-2 group"
              :class="[
                (isActive || isExactActive) && 'active',
                isOpen && 'is-open',
                mode === 'dark'
                  ? 'text-white-100 hover:bg-dark-70'
                  : 'hover:bg-primary-100 hover:text-black-100 hover:bg-opacity-5',
              ]"
              role="link"
              @click="navigate"
              v-tooltip="
                !isOpen
                  ? {
                      content: `<div style='max-width: 350px;'>After Your Report is done, use Advanced AI to Generate / Optimize ALL of your SEO content: URLs, Titles, METAs, Headings, Images and Content with the Perfect Metrics!</div>`,
                      html: true,
                      placement: 'auto',
                      distance: 10,
                    }
                  : null
              "
            >
              <span class="flex-shrink-0 w-6 h-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    d="M16.746 3.104a.5.5 0 0 0 0-.707l-1.06-1.061a.5.5 0 0 0-.707 0l-.957.957 1.767 1.768.957-.957Z"
                  />
                  <path
                    d="m15.082 4.768-1.768-1.768-3.699 3.7a2 2 0 0 0-.585 1.406l-.003.698a.25.25 0 0 0 .251.251l.698-.002a2 2 0 0 0 1.407-.586l3.7-3.7Z"
                  />
                  <path
                    d="M5.5 4.75c0-.69.56-1.25 1.25-1.25h3.25a.75.75 0 0 0 0-1.5h-3.25a2.75 2.75 0 0 0-2.75 2.75v10.5a2.75 2.75 0 0 0 2.75 2.75h6.5a2.75 2.75 0 0 0 2.75-2.75v-7.25a.75.75 0 0 0-1.5 0v7.25c0 .69-.56 1.25-1.25 1.25h-6.5c-.69 0-1.25-.56-1.25-1.25v-10.5Z"
                  />
                  <path
                    d="M7.75 10.5a.75.75 0 0 0 0 1.5h4.5a.75.75 0 0 0 0-1.5h-4.5Z"
                  />
                  <path
                    d="M7 14.25a.75.75 0 0 1 .75-.75h2.75a.75.75 0 0 1 0 1.5h-2.75a.75.75 0 0 1-.75-.75Z"
                  />
                </svg>
              </span>
              <div :class="isOpen === true ? 'hidden' : 'pt-[2px]'">
                <div class="leading-none">Article Builder</div>
                <div class="text-[10px] pt-2 leading-none">
                  Automate Your OnPage SEO​​
                </div>
              </div>
            </div>
          </RouterLink>
          <RouterLink
            :to="{ name: 'reports' }"
            v-slot="{ navigate, isActive, isExactActive }"
            :title="isOpen ? 'Reports' : ''"
            v-tooltip="{
              content: `${isOpen ? 'Reports' : ''}`,
              placement: 'auto',
              distance: 10,
            }"
            @click="redirect"
          >
            <div
              class="flex items-start p-2 relative rounded-md bg-opacity-5 gap-2 group"
              :class="[
                (isActive || isExactActive || isReportPages) && 'active',
                isOpen && 'is-open',
                mode === 'dark'
                  ? 'text-white-100 hover:bg-dark-70'
                  : 'hover:bg-primary-100 hover:text-black-100 hover:bg-opacity-5',
              ]"
              role="link"
              @click="navigate"
              v-tooltip="
                !isOpen
                  ? {
                      content: `<div style='max-width: 350px;'>Check the Results on all of your Existing Keyword Metrics</div>`,
                      html: true,
                      placement: 'auto',
                      distance: 10,
                    }
                  : null
              "
            >
              <span class="flex-shrink-0 w-6 h-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    d="M8.5 12.25a.75.75 0 0 0-1.5 0v2a.75.75 0 0 0 1.5 0v-2Z"
                  />
                  <path
                    d="M12.25 11.5a.75.75 0 0 1 .75.75v2a.75.75 0 0 1-1.5 0v-2a.75.75 0 0 1 .75-.75Z"
                  />
                  <path
                    d="M10.75 14.25v-3.5a.75.75 0 0 0-1.5 0v3.5a.75.75 0 0 0 1.5 0Z"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M6.75 2.5a2.75 2.75 0 0 0-2.75 2.75v9.5a2.75 2.75 0 0 0 2.75 2.75h6.5a2.75 2.75 0 0 0 2.75-2.75v-6.25a.75.75 0 0 0-.22-.53l-5.25-5.25a.75.75 0 0 0-.53-.22h-3.25Zm-1.25 2.75c0-.69.56-1.25 1.25-1.25h2.5v3.5c0 .966.784 1.75 1.75 1.75h3.5v5.5c0 .69-.56 1.25-1.25 1.25h-6.5c-.69 0-1.25-.56-1.25-1.25v-9.5Zm7.94 2.5-2.69-2.69v2.44c0 .138.112.25.25.25h2.44Z"
                  />
                </svg>
              </span>
              <div :class="isOpen === true ? 'hidden' : 'pt-[2px]'">
                <div class="leading-none">Reports</div>
                <div class="text-[10px] pt-2 leading-none">
                  Investigate Boosting Keywords​​​​
                </div>
              </div>
            </div>
          </RouterLink>
          <RouterLink
            :to="{ name: 'projects' }"
            v-slot="{ navigate, isActive, isExactActive }"
            :title="isOpen ? 'Projects' : ''"
            v-tooltip="{
              content: `${isOpen ? 'Projects' : ''}`,
              placement: 'auto',
              distance: 10,
            }"
            @click="redirect"
          >
            <div
              class="flex items-start p-2 relative rounded-md bg-opacity-5 gap-2 group"
              :class="[
                (isActive || isExactActive || isProjectsPage) && 'active',
                isOpen && 'is-open',
                mode === 'dark'
                  ? 'text-white-100 hover:bg-dark-70'
                  : 'hover:bg-primary-100 hover:text-black-100 hover:bg-opacity-5',
              ]"
              role="link"
              @click="navigate"
              v-tooltip="
                !isOpen
                  ? {
                      content: `<div style='max-width: 350px;'>Handy way to organize Reports into Projects.</div>`,
                      html: true,
                      placement: 'auto',
                      distance: 10,
                    }
                  : null
              "
            >
              <span class="flex-shrink-0 w-6 h-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    d="M8.25 10.75a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75Z"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M5.25 3.5a1.75 1.75 0 0 0-1.75 1.75v2c0 .595.297 1.12.75 1.436v5.064a2.75 2.75 0 0 0 2.75 2.75h6a2.75 2.75 0 0 0 2.75-2.75v-5.064c.453-.316.75-.841.75-1.436v-2a1.75 1.75 0 0 0-1.75-1.75h-9.5Zm9 5.5h-8.5v4.75c0 .69.56 1.25 1.25 1.25h6c.69 0 1.25-.56 1.25-1.25v-4.75Zm-9.25-3.75a.25.25 0 0 1 .25-.25h9.5a.25.25 0 0 1 .25.25v2a.25.25 0 0 1-.25.25h-9.5a.25.25 0 0 1-.25-.25v-2Z"
                  />
                </svg>
              </span>
              <div :class="isOpen === true ? 'hidden' : 'pt-[2px]'">
                <div class="leading-none">Projects</div>
                <div class="text-[10px] pt-2 leading-none">
                  Group Your Reports​​​​​​​
                </div>
              </div>
            </div>
          </RouterLink>
          <RouterLink
            v-if="userStore.hasZebraAIPermission()"
            :to="{ name: 'zebra-ai' }"
            v-slot="{ navigate, isActive, isExactActive }"
            :title="isOpen ? 'Beta Tools' : ''"
            v-tooltip="{
              content: `${isOpen ? 'Beta Tools' : ''}`,
              placement: 'auto',
              distance: 10,
            }"
            @click="redirect"
          >
            <div
              class="flex items-start p-2 relative rounded-md bg-opacity-5 gap-2 group"
              :class="[
                (isActive || isExactActive) && 'active',
                isOpen && 'is-open',
                mode === 'dark'
                  ? 'text-white-100 hover:bg-dark-70'
                  : 'hover:bg-primary-100 hover:text-black-100 hover:bg-opacity-5',
              ]"
              role="link"
              @click="navigate"
              v-tooltip="
                !isOpen
                  ? {
                      content: `<div style='max-width: 350px;'>Use AI to Analyze and Generate Helpful / Review Content, Workshop URL/Title/Meta/H1, AI Detection, AI Content Improver and Featured Snippet Generator!</div>`,
                      html: true,
                      placement: 'auto',
                      distance: 10,
                    }
                  : null
              "
            >
              <span class="flex-shrink-0 w-6 h-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6.52 3.83a4.768 4.768 0 0 1 6.423 5.374l2.783 2.782a2.644 2.644 0 0 1-3.74 3.74l-2.782-2.782a4.768 4.768 0 0 1-5.373-6.424.75.75 0 0 1 1.228-.256l1.646 1.647a.853.853 0 0 0 1.206-1.206l-1.646-1.646a.75.75 0 0 1 .256-1.228Zm1.809 1.17.643.644a2.353 2.353 0 1 1-3.327 3.327l-.644-.644a3.268 3.268 0 0 0 4.216 3.069.75.75 0 0 1 .748.187l3.082 3.082a1.144 1.144 0 1 0 1.618-1.618l-3.082-3.082a.75.75 0 0 1-.187-.748 3.268 3.268 0 0 0-3.067-4.217Z"
                  />
                </svg>
              </span>
              <div :class="isOpen === true ? 'hidden' : 'pt-[2px]'">
                <div class="leading-none">Beta Tools</div>
                <div class="text-[10px] pt-2 leading-none">
                  Check Out Our Beta AI Tools​​​​​
                </div>
              </div>
            </div>
          </RouterLink>
          <div
            class="flex items-center p-2 relative rounded-md bg-opacity-5 gap-2 cursor-pointer group"
            :class="[
              isOpen && 'is-open',
              mode === 'dark'
                ? 'text-white-100 hover:bg-dark-70'
                : 'hover:bg-primary-100 hover:text-black-100 hover:bg-opacity-5',
            ]"
            @click="viewCreditsStatus = true"
            :title="isOpen ? 'View Plan/Credits' : ''"
            v-tooltip="{
              content: `${isOpen ? 'View Plan/Credits' : ''}`,
              placement: 'auto',
              distance: 10,
            }"
          >
            <span class="flex-shrink-0 w-6 h-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M6.25 11.25a.75.75 0 0 0 0 1.5h2.75a.75.75 0 0 0 0-1.5h-2.75Z"
                />
                <path
                  fill-rule="evenodd"
                  d="M2.5 7.25a2.75 2.75 0 0 1 2.75-2.75h9.5a2.75 2.75 0 0 1 2.75 2.75v5.5a2.75 2.75 0 0 1-2.75 2.75h-9.5a2.75 2.75 0 0 1-2.75-2.75v-5.5Zm12.25-1.25c.69 0 1.25.56 1.25 1.25h-12c0-.69.56-1.25 1.25-1.25h9.5Zm1.25 3.25h-12v3.5c0 .69.56 1.25 1.25 1.25h9.5c.69 0 1.25-.56 1.25-1.25v-3.5Z"
                />
              </svg>
            </span>
            <span class="leading-none" :class="isOpen === true ? 'hidden' : ''"
              >View Plan / Credits</span
            >
          </div>
          <RouterLink
            :to="{
              name: 'seotraining',
            }"
            class="flex items-center p-2 relative rounded-md bg-opacity-5 gap-2 group"
            :class="[
              isOpen && 'is-open',
              mode === 'dark'
                ? 'text-white-100 hover:bg-dark-70'
                : 'hover:bg-primary-100 hover:text-black-100 hover:bg-opacity-5',
            ]"
            :title="isOpen ? 'Unlock SEO Training' : ''"
            v-tooltip="{
              content: `${isOpen ? 'Unlock SEO Training' : ''}`,
              placement: 'auto',
              distance: 10,
            }"
          >
            <span class="flex-shrink-0 w-6 h-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M5.273 6.28c-2.05 2.05-2.05 5.375 0 7.425.293.293.293.768 0 1.06-.293.293-.768.293-1.06 0-2.637-2.635-2.637-6.91 0-9.545.292-.293.767-.293 1.06 0 .293.293.293.767 0 1.06Z"
                />
                <path
                  d="M10 7.75c-1.243 0-2.25 1.007-2.25 2.25s1.007 2.25 2.25 2.25 2.25-1.007 2.25-2.25-1.007-2.25-2.25-2.25Z"
                />
                <path
                  d="M14.712 13.705c2.05-2.05 2.05-5.374 0-7.425-.293-.293-.293-.767 0-1.06.293-.293.768-.293 1.061 0 2.636 2.636 2.636 6.91 0 9.546-.293.293-.768.293-1.06 0-.293-.293-.293-.768 0-1.061Z"
                />
                <path
                  d="M7.29 8.048c-1.073 1.074-1.073 2.815 0 3.89.294.292.294.767 0 1.06-.292.293-.767.293-1.06 0-1.66-1.66-1.66-4.35 0-6.01.293-.293.768-.293 1.06 0 .294.292.294.767 0 1.06Z"
                />
                <path
                  d="M12.694 11.937c1.074-1.074 1.074-2.815 0-3.889-.292-.293-.292-.768 0-1.06.293-.293.768-.293 1.061 0 1.66 1.66 1.66 4.35 0 6.01-.293.293-.768.293-1.06 0-.293-.293-.293-.768 0-1.06Z"
                />
              </svg>
            </span>
            <div :class="isOpen === true ? 'hidden' : ''">
              <div class="leading-none">Unlock SEO Training</div>
            </div>
          </RouterLink>
        </div>
        <div
          class="flex items-center justify-center p-2 rounded-md gap-2 cursor-pointer"
          @click="toggleOpen"
          :title="isOpen ? 'Collapse' : ''"
          :class="[
            mode === 'dark'
              ? 'text-white-100 bg-dark-70'
              : 'bg-primary-100 hover:text-black-100 bg-opacity-5',
          ]"
        >
          <span class="w-6 h-6">
            <svg
              v-if="isOpen"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                d="M12.75 3.5a.75.75 0 0 0 0 1.5h1.19l-3.22 3.22a.75.75 0 0 0 1.06 1.06l3.22-3.22v1.19a.75.75 0 0 0 1.5 0v-3a.75.75 0 0 0-.75-.75h-3Z"
              />
              <path
                d="M7.25 16.5a.75.75 0 0 0 0-1.5h-1.19l3.22-3.22a.75.75 0 1 0-1.06-1.06l-3.22 3.22v-1.19a.75.75 0 0 0-1.5 0v3c0 .414.336.75.75.75h3Z"
              />
            </svg>
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                d="M13.75 9.5a.75.75 0 0 0 0-1.5h-.69l3.22-3.22a.75.75 0 0 0-1.06-1.06l-3.22 3.22v-.69a.75.75 0 0 0-1.5 0v2.5c0 .414.336.75.75.75h2.5Z"
              />
              <path
                d="M6.25 10.5a.75.75 0 0 0 0 1.5h.69l-3.22 3.22a.75.75 0 1 0 1.06 1.06l3.22-3.22v.69a.75.75 0 0 0 1.5 0v-2.5a.75.75 0 0 0-.75-.75h-2.5Z"
              />
            </svg>
          </span>
        </div>
      </div>
    </div>
    <div
      class="transition flex-1 custom:ml-0"
      :class="[
        !isLoggedIn || $route.meta.isHideSidebar
          ? ''
          : isOpen === true
          ? 'ml-70'
          : 'ml-60',
      ]"
    >
      <RouterView :mode="mode" @toggle="toggle" :key="$route.path" />
    </div>
    <template v-if="isLoggedIn && $route.name !== 'signup'">
      <div
        v-if="viewCreditsStatus"
        class="fixed inset-0 z-[9999] flex items-center justify-center p-4 bg-opacity-40"
        :class="mode === 'dark' ? 'bg-white-100' : 'bg-black-100'"
        tabindex="-1"
      >
        <div
          class="max-w-3xl rounded-xl w-full mx-auto"
          :class="mode === 'dark' ? 'bg-dark-70' : 'bg-white-100'"
        >
          <div
            class="flex items-center justify-between px-4 py-2 border-b"
            :class="mode === 'dark' ? 'border-dark-50' : 'border-border-100'"
          >
            <h5 class="text-lg font-semibold leading-none">Credits</h5>
            <svg
              @click="viewCreditsStatus = false"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              class="cursor-pointer w-9 p-1 rounded-full"
              :class="
                mode === 'dark' ? 'hover:bg-dark-50' : 'hover:bg-gray-100'
              "
            >
              <path
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="32"
                d="M368 368L144 144M368 144L144 368"
              ></path>
            </svg>
          </div>
          <div class="bg-opacity-30 max-h-modal overflow-y-auto p-4">
            <div class="grid gap-4">
              <div class="flex items-center justify-between">
                <p class="font-semibold">
                  Your Monthly Usage Stats and Plan Type
                </p>
                <div
                  class="button primary-button small-button"
                  @click="addMoreCredits"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    class="w-5 h-5 mr-1 -ml-1"
                    fill="currentColor"
                  >
                    <path
                      d="M2.5 6.75a2.75 2.75 0 0 1 2.75-2.75h9.5a2.75 2.75 0 0 1 2.75 2.75v1.25a.75.75 0 0 1-1.5 0v-1.25c0-.69-.56-1.25-1.25-1.25h-9.5c-.69 0-1.25.56-1.25 1.25v.5h8a1 1 0 1 1 0 2h-8v4c0 .69.56 1.25 1.25 1.25h4.052a.75.75 0 0 1 0 1.5h-4.052a2.75 2.75 0 0 1-2.75-2.75v-6.5Z"
                    />
                    <path
                      d="M5.75 11.5a.75.75 0 0 0 0 1.5h2a.75.75 0 0 0 0-1.5h-2Z"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M13 12.25a1 1 0 0 0-1 1v2.75a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-2.75a1 1 0 0 0-1-1v-.75a2 2 0 1 0-4 0v.75Zm2.5 0v-.75a.5.5 0 0 0-1 0v.75h1Z"
                    />
                  </svg>
                  Add More Credits
                </div>
              </div>
              <ChartComponent />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { computed } from "vue";
import { productFruits } from "product-fruits";
import ApiRequest from "./libs/ApiRequest";
import helper from "./libs/helper";
import ChartComponent from "./components/ChartComponent.vue";
import { useUserStore } from "./stores/user";
export default {
  setup() {
    const userStore = useUserStore();
    return {
      isLoggedIn: computed(() => userStore.isLoggedIn),
      accessToken: computed(() => userStore.accessToken),
      isLoading: computed(() => userStore.isloading),
      loaderLable: computed(() => userStore.loaderLable),
      isFullPage: computed(() => userStore.isFullPage),
      user: computed(() => userStore.user),
      userStore,
    };
  },
  components: {
    ChartComponent,
  },
  data() {
    return {
      mode: localStorage.getItem("mode") || "light",
      accessToken: "",
      collapseSidebar: localStorage.getItem("menu-sidebar") || "open",
      loader: null,
      isOpen: false,
      viewCreditsStatus: false,
      isReportPages: false,
      isKeywordPlannerPage: false,
      isProjectsPage: false,
      isCollapsed: false,
    };
  },
  methods: {
    addMoreCredits() {
      this.viewCreditsStatus = false;
      this.$router.push({ name: "account-subscription" });
    },
    toggleOpen() {
      if (this.isOpen == false) {
        this.isOpen = true;
      } else {
        this.isOpen = false;
      }
    },
    toggle() {
      if (this.mode === "dark") {
        this.mode = "light";
      } else {
        this.mode = "dark";
      }
      localStorage.setItem("mode", this.mode);
    },
    onCancelLoader() {
      this.userStore.setIsLoading(!1);
    },
    async getCurrentUser() {
      try {
        const URL = `/api/auth/current-user`;
        const res = await ApiRequest().get(URL);
        if (res.status !== 200) {
          throw new Error("Something went wrong!");
        }
        this.userStore.setUser(res.data);
      } catch (error) {
        const extractedError = helper.extractAlert(error, "app");
        this.$toast.error(extractedError.alert.message);
      }
    },
    toggleSidebar() {
      if (this.collapseSidebar === "open") {
        this.collapseSidebar = "close";
      } else {
        this.collapseSidebar = "open";
      }
      localStorage.setItem("menu-sidebar", this.collapseSidebar);
    },
    handleIsLoading() {
      let options = {
        canCancel: false,
        onCancel: this.onCancelLoader,
        loader: "spinner",
        height: 45,
      };
      if (this.isFullPage) {
        options["container"] = this.isFullPage;
      }
      if (this.isLoading) {
        this.loader = this.$loading.show(options, {
          after: this.loaderLable,
        });
      } else if (this.loader) {
        this.loader.hide();
      }
    },
    initProductFruits() {
      if (this.userStore.user && this.userStore.user.email) {
        const { email, first_name, last_name, phone_number } =
          this.userStore.user;
        if (!window.productFruits) {
          productFruits.init(
            "nG7StDYhiGiO5TOh",
            "en",
            {
              username: email,
              email,
              first_name,
              last_name,
              phone_number,
              props: {
                uuid: (this.$route.params && this.$route.params.uuid) || "",
                routeName: this.$route.name || "",
              },
            },
            {
              disableLocationChangeDetection: true,
            }
          );
        } else {
          if (window.productFruits.identifyUser) {
            window.productFruits.identifyUser({
              username: email,
              email,
              first_name,
              last_name,
              phone_number,
              props: {
                uuid: (this.$route.params && this.$route.params.uuid) || "",
                routeName: this.$route.name || "",
              },
            });
          }
        }
      }
    },
    redirect() {
      this.isCollapsed = false;
      window.dispatchEvent(
        new CustomEvent("isCollapsed", {
          detail: {
            isCollapsed: this.isCollapsed,
          },
        })
      );
    },
  },
  mounted() {
    this.initProductFruits();
    window.addEventListener("collapseSidebar", (event) => {
      this.isCollapsed = event.detail.isCollapsed;
    });
  },
  watch: {
    $route(to, from) {
      const reloadUserOnRoutes = [
        "subscription-confirmed",
        "subscription-upgrade",
        "subscription-cancelled",
        "account-subscription",
      ];
      if (reloadUserOnRoutes.includes(to.name)) {
        this.getCurrentUser();
      }
      if (["editor", "editor-public"].includes(to.name)) {
        this.collapseSidebar = "close";
      }
      if (to.name === "reports" || to.name === "serp-report-ta") {
        this.isReportPages = true;
      } else {
        this.isReportPages = false;
      }
      if (to.name.includes("KeywordPlanner")) {
        this.isKeywordPlannerPage = true;
      } else {
        this.isKeywordPlannerPage = false;
      }
      if (to.name === "projects" || to.name === "edit-project") {
        this.isProjectsPage = true;
      } else {
        this.isProjectsPage = false;
      }
      const steps = [
        "/keyword-discovery",
        "/serp/keyword-spy",
        "/serp/reports",
        "/article-builder",
      ];
      const currentIndex = steps.indexOf(to.path);
      if (currentIndex > -1) {
        this.userStore.setCurrentDashboardStep(currentIndex + 1);
      }
    },
    user(val) {
      if (val.email) {
        this.initProductFruits();
      }
    },
    isLoading() {
      this.handleIsLoading();
    },
  },
};
</script>
