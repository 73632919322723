<template>
  <div>
    <div class="grid grid-cols-2 gap-4 custom:grid-cols-1">
      <ul class="quota-list small-screen hidden">
        <li>
          <h6 class="fw-bold text-capitalize text-white">
            {{ tier }} On-page Tool
          </h6>
          <p class="mb-0">
            {{ usedQuota }} / {{ usedQuota + remainingQuota }} Credits
            (Reports/Articles)
          </p>
        </li>
        <li>
          <h6 class="fw-bold text-white">Keyword Discovery</h6>
          <p class="mb-0">
            {{ difficulty_used_quota }} /
            {{ difficulty_used_quota + difficulty_remaining_quota }}
            Keyword Difficulty Credits
          </p>
        </li>
      </ul>
      <div>
        <div
          class="p-3 bg-primary-100 text-white-100 rounded-tl-lg rounded-tr-lg"
        >
          <h6 class="font-bold text-base mb-2.5 leading-none capitalize">
            {{ tier }} On-page Tool
          </h6>
          <ul class="grid gap-1 list-disc pl-3">
            <li class="font-medium text-sm">
              {{ usedQuota }} Credits Used (Reports/Articles)
            </li>
            <li class="font-medium text-sm">
              {{ usedQuota + remainingQuota }} Total Credits
            </li>
          </ul>
        </div>
        <p
          class="px-3 bg-black-100 text-white-100 text-center py-2 rounded-bl-lg rounded-br-lg font-medium text-sm"
        >
          Credits {{ tier === "Trial" ? "expire" : "renew" }} on
          {{ this.subscribedUntil }}
        </p>
      </div>
      <div>
        <div
          class="p-3 bg-primary-100 text-white-100 rounded-tl-lg rounded-tr-lg"
        >
          <h6 class="font-bold text-base mb-2.5 leading-none">
            Keyword Discovery
          </h6>
          <ul class="grid gap-1 list-disc pl-3">
            <li class="font-medium text-sm">
              {{ difficulty_used_quota }} Keyword Difficulty Credits Used
            </li>
            <li class="font-medium text-sm">
              {{ difficulty_used_quota + difficulty_remaining_quota }} Total
              Keyword Difficulty Credits
            </li>
          </ul>
        </div>
        <p
          class="px-3 bg-black-100 text-white-100 text-center py-2 rounded-bl-lg rounded-br-lg font-medium text-sm"
        >
          Credits {{ tier === "Trial" ? "expire" : "renew" }} on
          {{ this.subscribedUntil }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import helper from "../libs/helper";
import { useUserStore } from "../stores/user";
import ApiRequest from "../libs/ApiRequest";
export default {
  name: "ChartComponent",
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  props: {
    isSmallSizeChangeUI: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      difficulty_used_quota: 0,
      difficulty_remaining_quota: 0,
      usedQuota: 0,
      remainingQuota: 0,
      subscribedUntil: "",
      tier: "",
    };
  },
  mounted() {
    if (
      this.$route &&
      this.$route.name &&
      this.$route.fullPath.includes("article-builder/public")
    ) {
      return;
    }
    this.loadUserQuotas();
  },
  methods: {
    async loadUserQuotas() {
      try {
        const { data } = await ApiRequest().get(`/api/auth/quotas`);
        const {
          difficulty_used_quota = 0,
          difficulty_remaining_quota = 0,
          used_quota = 0,
          remaining_quota = 0,
          subscribed_until = "",
          tier = "",
        } = data;
        if (data) {
          this.userStore.setQuotas(data);
        }
        this.remainingQuota =
          remaining_quota === null ? 0 : parseInt(remaining_quota);
        this.usedQuota = used_quota === null ? 0 : parseInt(used_quota);
        this.difficulty_remaining_quota =
          difficulty_remaining_quota === null
            ? 0
            : parseInt(difficulty_remaining_quota);
        this.difficulty_used_quota =
          difficulty_used_quota === null ? 0 : parseInt(difficulty_used_quota);
        this.subscribedUntil = moment(subscribed_until).format("DD MMMM YYYY");
        this.tier = tier;
      } catch (error) {
        const extractedError = helper.extractAlert(error, "reports");
        this.$toast.error(extractedError.alert.message);
      }
    },
  },
};
</script>
